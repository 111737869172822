<template>
  <div class="row mt-md-0 mt-5">

    <!-- Form -->
    <div class="col-md-12">
      <Card :title="'Detail Poliklinik'">
        <template v-slot:body>
          <div class="row align-items-center">

            <!-- Profile Image -->
            <div class="col-md-4">
              <div class="d-flex justify-content-center align-items-center image-container">
                <div style='height: 350px; width: 350px; position: relative'>
                  <img
                    class="image"
                    src="/images/polyclinic-splash.svg"
                    @load="onImageLoad()"
                  >
                </div>
              </div>
            </div>

            <!-- Table Data -->
            <div
              class="col"
              v-if="imgLoaded"
            >
              <table class="table mb-3">
                <tr>
                  <td style="width: 25%"><strong>Nama</strong></td>
                  <td>{{ data.name }}</td>
                </tr>
                <tr>
                  <td><strong>Deskripsi</strong></td>
                  <td>{{ data.description }}</td>
                </tr>
              </table>

              <button
                @click="$router.push({path: '/polyclinic/edit/' + data.id})"
                class="btn mx-1 btn-success"
              ><i class="far fa-fw fa-edit"></i> Edit</button>
              <button
                @click="deleteData"
                class="btn mx-1 btn-danger"
              ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
            </div>

          </div>
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {
        name: "",
        description: ""
      },
      // Other
      imgLoaded: false
    }
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('polyclinics/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/polyclinic/list')
      }
    },

    async get() {
      this.data = await module.get('polyclinics/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/polyclinic/list')
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Poliklinik", route: "" },
      { title: "Daftar Poliklinik", route: "/polyclinic/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
  }

}

</script>